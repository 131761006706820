.App {
    height: 100vh;
    width: 100vw;
}

a:link, a:visited, a:hover, a:active {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0px;
}

/* header */

header {
    display: flex;
    position: sticky;
    top: 0px;
    align-items: center;
    justify-content: space-between;
    height: 15vh;
    width: 100%;
    border-bottom: 1px solid rgb(245,245,245);
    box-shadow: 0px 1px 10px  rgb(230,230,230);
    background-color: white;
}

#logo {
    margin-left: 15.3%;
    margin-top: 1px;
}

#menu {
    display: flex;
    justify-content: space-around;
    width: 459px;
    margin-right: 15.3%;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.3em;
    letter-spacing: 0.4px;
}

/* body */

body {
    display: flex;
    flex-direction: column;
    height: 83vh;
    width: 100%;
}

#heading {
    display: flex;
    align-items: center;
    height: 13vh;
    width: 100%;
    background-color: #f9faf5;
    margin-bottom: 50px;
}

.page-title {
    margin-left: 15.3%;
    font-size: 1.3571em;
    color: #232a34;
    font-weight: 700;
    padding: 1.8em 0 1.8em;
    line-height: 1.2em;
    letter-spacing: 0.5px;
}

#currency-converter-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-left: 15.3%;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid rgb(230,230,230);
    border-radius: 6px;
    background-color: #f9faf5;
    font-family: Roboto,Arial,sans-serif;
}

#currency-converter-box:hover {
    box-shadow: 0 2px 5px 2px rgba(0,0,0,.04);
}

#description {
    margin-bottom: 20px;
    margin-left: 20px;
}

.heading-1 {
    color: rgb(50,50,50);
}

.heading-2 {
    font-size: 40px;
    font-weight: normal;
}

#forms {
    margin-left: 20px;
}

.form-1 {
    display: flex;
 }

 .input {
    width: 200px;
    padding-left: 10px;
    border: 1px solid rgb(230,230,230);
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: 6px;
    margin-bottom: 10px;
    font-size: 16px;
 }

.form-2 {
    display: flex;
    align-items: center;
}

.target_amount {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;
    padding-left: 10px;

}

.select-css {
	display: block;
	font-size: 16px;
	font-family: Roboto,Arial,sans-serif;
	line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    margin-right: 10px;
    margin-bottom: 10px;
	width: 80px;
	border: 1px solid rgb(230,230,230);
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: 6px;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#ffffff 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}

.select-css:hover {
	border-color: rgb(230,230,230);
}

.select-css:focus {
    outline: none;
}

.select-css option {
	font-weight:normal;
}

/* footer */

#footer-1 {
    display: flex;
    height: 45vh;
    width: 100%;
    padding-top: 38px;
    background-color: #171f2a;
    font-family: Montserrat;
    color: white;
}

.left-footer-text {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin-left: 15.3%;
    margin-right: 40px;
}

.about {
    font-size: 1.08em;
    font-weight: 400;
    line-height: 1.16em;
}

.general-info {
    font-size: 1.08em;
    font-weight: 400;
    line-height: 1.16em;
    margin-bottom: 1em;
}

.bottom-logo {
    width: 120px;
    margin: 0 0.7857em 1.2em 0;
}

.bottom-description {
    margin-bottom: 4em;
    color: #acb4b6;
    font-size: 13px;
    line-height: 1.8;
}

.address {
    font-size: 14px;
}

#footer-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    background-color: #0f151c;
    font-family: Montserrat;
    font-size: 12.25px;
    color: #cecece;
}

.right-footer-text {
    display: flex;
    flex-direction: column;
    width: 20%;
}

li {
    list-style-type: none;
    color: #807F83;
    margin-bottom: 6px;
    font-size: 14px;
    font-family: Arial;
    letter-spacing: 1px;
}

ul li:before {
    content: "\203A";
    color: #AB0634;
    
    font-size: 22px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: -38px;
}

@media (max-width: 1000px) {
    #menu {
        display: none;
    }

    #currency-converter-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        margin-left: 5%;
    }

    .heading-2 {
        word-wrap: break-word;
    }

    #footer-1 {
        display: flex;
        padding-left: 0%;
        height: 120vh;
        width: 100%;
        padding-top: 38px;
        background-color: #171f2a;
        font-family: Montserrat;
        color: white;
    }
}

@media (max-width: 370px) {
    .input {
        max-width: 50%;
     }
}